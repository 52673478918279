<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Generate Student Wise Bill
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <add-button icon="search" :permission="'routine-read'" @action="searchStudent"
                                        v-if="enrollCode">Search
                            </add-button>
                        </transition>
                    </v-card-title>

                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs3 sm3>
                                <v-select :disabled="gradeLoading" :loading="gradeLoading" :items="grades" class="pa-0"
                                          label="Grade" v-model="grade" outlined dense/>
                            </v-flex>
                            <v-flex xs3 sm3>
                                <v-select :disabled="!grade" :items="sections" class="pa-0" label="Section"
                                          v-model="section" outlined dense/>
                            </v-flex>
                            <v-flex xs3 sm3>
                                <v-select :disabled="!section" :items="months" class="pa-0" label="Month"
                                          v-model="month" outlined dense/>
                            </v-flex>
                            <v-flex xs3 sm3>
                                <v-text-field :disabled="!month || month > 12" v-model="enrollCode" class="pa-0"
                                              label="Enroll" outlined dense/>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-card-title class="title">
                        <v-spacer></v-spacer>

                        <span v-if="generate">
                            <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                        leave-active-class="animated fadeOutRight">
                                     <add-button v-if="this.grade" @action="generateBill" :permission="'section-read'"
                                                 icon="play_for_work">Generate</add-button>
                                <!-- <v-btn outlined @click="generateBill" ma-0 small>Generate</v-btn> -->
                                </transition>
                            </span>
                        <span v-if="(is_journal_entry == 0)&& print">
                            <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                        leave-active-class="animated fadeOutRight">
                                        <add-button @action="reverseBill" :permission="'section-read'" icon="refresh">Rollback</add-button>
                                <!-- <v-btn outlined @click="reverseBill" ma-0 small>Reverse</v-btn> -->
                            </transition>
                        </span>
                    </v-card-title>

                    <v-data-table :headers="headers" hide-default-footer
                                  :items="form.items.data" :search="search"
                                  :expand="expand"
                                  item-key="name"
                                  :options.sync="pagination" :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr :class="(item.bills.length > 0) ? (item.bills[0].invoice_no ? 'highlight': ''):''"
                                @click="expanded = !expanded">
                                <td class="text-xs-left">{{ item.enroll_code }}
                                    <span v-if="item.bills.length">
                                        <v-icon small v-if="item.bills[0].invoice_no" color="teal"> 
                                        check_circle
                                        </v-icon>
                                    </span>
                                </td>
                                <td class="text-xs-left"> {{ (item.bills.length > 0) ?
                                    (item.bills[0].invoice_no ? item.bills[0].invoice_no.split('-').pop() :
                                    'N/A') : 'N/A' }}
                                </td>
                                <td class="text-xs-left">{{ item.roll }}</td>
                                <td class="text-xs-left">{{item.name}}</td>
                                <td class="text-xs-left">{{item.section }}</td>
                                <td class="text-xs-left">{{(item.bills.length > 0) ?
                                    totalAmount(item.bills[0],index) : ''}}
                                </td>
                                <td class="text-xs-right">
                                    <v-btn v-if="is_journal_entry == 2" small icon color="primary" class="pa-0 ma-1"
                                           @click="singlePrint(item.enroll_code)" :disabled="(item.bills.length > 0) ? (props.item.bills[0].invoice_no ?
                                    false : true) : true">
                                        <v-icon dark>print</v-icon>
                                    </v-btn>
                                    <!-- <v-icon v-if="print" color="success" outlined small
                                           @click="singlePrint(props.item.enroll_code)" :disabled="(props.item.bills.length > 0) ? (props.item.bills[0].invoice_no ?
                                    false : true) : true ">print
                                    </v-icon> -->
                                </td>
                            </tr>
                        </template>
                        <template v-slot:expand="props">
                            <span v-if="props.item.bills.length > 0" style="width: 100%">
                            <table width="100%">
                                <thead>
                                        <tr>
                                            <th class="text-xs-left"><strong>Fee Head</strong></th>
                                            <th class="text-xs-left"><strong>Fee Head Amount</strong></th>
                                            <th class="text-xs-left"><strong>Discount</strong></th>
                                            <th class="text-xs-left"><strong>Scholarship</strong></th>
                                            <th class="text-xs-left"><strong>Paid Amount</strong></th>
                                            <th class="text-xs-left"><strong>Due Amount</strong></th>
                                            <th class="text-xs-left"><strong>Action</strong></th>
                                        </tr>
                                        <tr v-for="(x,k) in props.item.bills[0].details" :key="k"
                                            style="height: 0px">
                                            <td class="text-xs-left">{{x.fee_head}}</td>
                                            <td class="text-xs-left">{{x.fee_head_amount}}</td>
                                            <td class="text-xs-left">{{x.discount}}%</td>
                                            <td class="text-xs-left">{{x.scholarship}}%</td>
                                            <td class="text-xs-left">{{x.paid_amount}}</td>
                                            <td class="text-xs-left">{{x.due_amount}}</td>
                                            <td v-if="x.extra && parseInt(x.extra) === 1 && is_journal_entry  === 0">
                                                <delete-button permission="fee-delete" @agree="extraDelete(x)"/> 
                                            </td>
                                            <td v-else>-</td>
                                        </tr>
                                   
                                </thead>
                                <tfoot>
                                    <tr>
                                        <td :colspan="headers.length" style="text-align: right;">
                                            <add-button v-if="is_journal_entry == 0" permission="section-read"
                                                        @action="addExtra(props.item)">Add Extra
                                            </add-button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                            </span>
                            <span v-else style="margin-left: 30px">
                                No Bill generated
                            </span>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="confirmDialog" persistent max-width="290">
                <v-card>
                    <v-card-title class="title pa-3 warning white--text">
                        <v-icon class="mr-2 white--text">warning</v-icon>
                        Please, wait!
                    </v-card-title>
                    <v-card-text class="ma-0">{{warningMessage}}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" outlined small @click="confirmDialog = false">No</v-btn>
                        <v-btn v-if="generate" color="warning" outlined small @click="generateBill">Yes</v-btn>
                        <v-btn v-if="print" color="warning" outlined small @click="reverseBill">Yes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="reasonDialog" persistent max-width="290">
                <v-card>
                    <v-card-title class="title pa-3">
                        <v-textarea outlined name="input-7-4" label="Reason to Rollback" v-model="reason"></v-textarea>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" outlined small @click="reasonDialog = false">Cancel</v-btn>
                        <v-btn :disabled="!reason" color="warning" outlined small @click="rollBack">Rollback</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="addDialog" persistent max-width="290">
                <v-card>
                    <v-card-title class="title pa-3">
                        <v-select :items="fees" class="pa-0" label="Fee Head" v-model="fee_head_id"/>
                        <v-text-field autocomplete="off" label="Fee Amount" required class="pa-0 pt-3" v-model="total"/>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" outlined small @click="addDialog = false">Cancel</v-btn>
                        <v-btn :disabled="!fee_head_id" color="warning" outlined small @click="submit">Add</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    export default {
        data: () => ({
            gradeLoading: false,
            form: new Form({
                bill_month: '',
                grade_id: '',
                enroll: '',

            }, '/api/invoice'),
            search: null,
            pagination: {
                rowsPerPage: 10
            },
            headers: [
                {text: 'Enroll Code', align: 'left', value: 'enroll_code', sortable: false},
                {text: 'Invoice No', align: 'left', value: 'invoice_no', sortable: false},
                {text: 'Roll No', align: 'left', value: 'amount', sortable: false},
                {text: 'Name', align: 'left', value: 'generate_bill', sortable: false},
                {text: 'Section', align: 'left', value: 'section', sortable: false},
                {text: 'Fee Amount', align: 'left', value: 'fee_head_amount', sortable: false},
                {text: 'Action', align: 'right', value: 'action', sortable: false},
            ],
            grades: [],
            grade: '',
            enrollCode: '',
            expand: false,
            sections: [],
            section: '',
            enroll_id: '',
            months: [],
            reason: '',
            reasonDialog: false,
            month: '',
            generate: false,
            print: false,
            billingSetting: {},
            confirmDialog: false,
            warningMessage: '',
            invoice_no: '',
            billing: '',
            fee_head_amount: '',

            fee_heads: [],
            fee_head: '',
            fees: [],
            fee_head_id: '',
            feeAmounts: [],
            itemData: [],
            total: '',
            addDialog: false,
            is_bill_generate: 0,
            is_journal_entry: 0,
        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            this.getGrades();
        },

        watch: {
            'pagination': function () {
                // this.get();
            },
            'batch': function (value) {
            },
            'month': function (value) {
                this.enrollCode = '';
                if (this.grade) {
                    this.getBilling();
                }
                this.form.items.data = [];
                // this.get();
                this.print = false;
                this.generate = false;
            },
            'grade': function (value) {
                this.section = '',
                    this.getSection();

            },
            'section': function (value) {
                this.month = '';
                if (this.grade) this.getMonth();
                this.generate = false;
                this.print = false;
                this.form.items.data = [];
            },
            'fee_head_id': function (value) {
                this.feeAmounts = this.fees.find(res => res.value == value);
                this.fee_head_amount = this.feeAmounts.amount,
                    this.total = this.feeAmounts.amount,
                    this.fee_head = this.feeAmounts.text;
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&billMonth=' + this.month + '&gradeId=' + this.grade + '&sectionId=' + this.section + '&enrollCode=' + this.enrollCode.trim();
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    if (data.stat) {
                        this.is_bill_generate = data.stat.is_bill_generate;
                        this.is_journal_entry = data.stat.is_journal_entry;
                    } else {
                        this.is_bill_generate = 0;
                        this.is_journal_entry = 0;
                    }

                    if (data.data.length > 0) {

                        if (data.data[0].bills.length > 0) {
                            this.enroll_id = data.data[0].bills[0].enroll_id;
                            this.invoice_no = data.data[0].bills[0].invoice_no;
                            this.print = true
                            this.generate = false
                        } else {
                            this.print = false;
                            this.generate = true;
                        }
                        if (!data.enroll && this.enrollCode) {
                            this.$events.fire('notification', {message: 'No record found in system.', status: 'error'});
                            this.generate = false;
                            this.print = false;
                        }
                    } else {
                        this.$events.fire('notification', {message: 'No record found in system.', status: 'error'});
                        this.generate = false;
                        this.print = false;
                    }
                }).catch(e => {
                    this.$events.fire('notification', {message: e.response.data.message, status: 'error'});
                })
            },

            save() {
                this.form.batchId = this.batch.id;
                this.form.grade_id = this.grade;
                this.form.bill_month = this.month;
                this.form.store()
            },

            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },
            getFeeHead() {
                this.$rest.get('/api/fee-head').then(({data}) => {
                    this.fee_heads = data.data.map(item => {
                        return {value: item.id, text: item.title}
                    });
                })
            },

            generateBill() {
                this.warningMessage = "Are you sure you want to generate bill?"
                if (!this.confirmDialog) {
                    this.confirmDialog = true;
                } else {
                    let formData = new FormData();
                    formData.set('enroll_code', this.enrollCode.trim());
                    formData.set('section_id', this.section);
                    formData.set('bill_month', this.month);
                    formData.set('billing', this.billing);

                    this.$rest.post('/api/invoice-single', formData).then(({data}) => {
                        this.get();
                        this.confirmDialog = false;
                        this.generate = false
                        this.print = true;
                    }).catch(e => {
                        this.$events.fire('notification', {message: data.message, status: 'error'});
                    })
                }
            },
            reverseBill() {
                this.reason = '';
                if (!this.confirmDialog) {
                    this.warningMessage = "Are you sure you want to Reverse generated bill?"
                    this.confirmDialog = true;
                } else {
                    this.reasonDialog = true;
                    this.confirmDialog = false;

                }
            },
            singlePrint(enCode) {
                let url = '?billing=' + this.billing
                    + '&billMonth=' + this.month
                    + '&enrollCode=' + enCode;
                this.$rest.get('/api/download/bill/single').then(({data}) => {
                    window.open(data.data.download_url + url)
                });
            },
            getMonth() {
                if (this.grade) {
                    this.$rest.get('/api/invoice/billNow?gradeId=' + this.grade).then(({data}) => {
                        this.months = data.map(item => {
                            return {value: item.generate_month, text: item.bill_month_name, billing: item.billing}
                        });
                    })
                }
            },
            rollBack() {
                let formData = new FormData();
                formData.append('invoice_no', this.invoice_no);
                formData.append('enroll_id', this.enroll_id);
                formData.append('bill_month', this.month);
                formData.append('billing', this.billing);
                formData.append('reason', this.reason);
                this.$rest.post('/api/invoice/rollback-single', formData).then(({data}) => {
                    this.reasonDialog = false;
                    this.generate = false;
                    this.print = false;
                    this.grade = '';
                    this.form.items.data = [];

                    this.$events.fire('notification', {message: data.message, status: 'success'});
                }).catch(e => {
                    this.$events.fire('notification', {message: e.response.data.message, status: 'error'});

                })
            },

            getSection() {
                if (this.grade) {
                    this.$rest.get('/api/section?grade=' + this.grade).then(({data}) => {
                        this.sections = data.data.map(item => {
                            return {value: item.id, text: item.name}
                        });
                    })
                }
            },
            searchStudent() {
                this.get();
            },

            getBilling() {
                let $this = this
                this.filterData = this.months.filter(function (data) {
                    if (data.value == $this.month) {
                        return data;
                    }
                });
                this.billing = this.filterData[0].billing;
            },
            addExtra(item) {
                this.itemData = item;
                this.fee_head_amount = '';
                this.fee_head_id = ''
                this.addDialog = true;
                this.$rest.get('/api/fee?gradeId=' + this.grade + '&batchId=' + this.batch.id).then(({data}) => {
                    this.fees = data.data.map(item => {
                        return {value: item.fee_head_id, text: item.fee_head.title, amount: item.amount}
                    });
                });
            },
            submit() {
                this.addDialog = false;
                this.$rest.post('/api/invoice/add-item', {
                    invoice_no: this.itemData.bills[0].invoice_no,
                    fee_head: this.fee_head,
                    fee_head_amount: this.fee_head_amount,
                    fee_head_id: this.fee_head_id,
                    total: this.total,
                    enroll_id: this.itemData.enroll_id
                }).then(({data}) => {
                    this.get();
                    this.$events.fire('notification', {message: data.message, status: 'success'});

                }).catch(e => {
                    this.$events.fire('notification', {message: data.message, status: 'error'});
                })
            },
            extraDelete(item) {
                this.$rest.delete('/api/invoice/delete-item/' + item.id).then(({data}) => {
                    this.get();
                    this.$events.fire('notification', {message: data.message, status: 'success'});

                }).catch(e => {
                    this.$events.fire('notification', {message: e.response.data.message, status: 'error'});
                })
            },
            totalAmount(item, index) {
                let amount = 0;
                item.details.map(res => {
                    amount += parseFloat(res.due_amount)
                });

                let tableRows = document.getElementsByClassName('highlight')
                if (tableRows.length) {
                    if (amount <= 0) {
                        // tableRows.item(index).style.background = '#a9fdab';
                    } else if (amount < item.amount) {
                        // tableRows.item(index).style.background = '#fbcba7';
                    } else {
                        // tableRows.item(index).style.background = '';
                    }
                }

                // if(tableRows)
                // tableRows.style.background='red';
                // if(item.amount-amount > 0){

                // }

                // console.log(tableRows);
                return amount;
            }
        }
    }
</script>
<style lang="scss">
    .highlight {
        td {
            font-weight: bold !important;
        }
    }

    tr.highlight {
        cursor: pointer;
    }
</style>